import { useEffect, useState, useContext } from 'react';
import SenderEmailLists from '../components/SenderEmailLists'
import TabBar from '../components/TabBar'
import EmailingAPI from '../apis/EmailingAPI';
import ErrorModal from '../errors/ErrorModal';
import LoadingIcon from '../components/LoadingIcon';
import { campaignExtractor } from '../utils/campaignExtractor';
import { AuthenticationContext } from '../contexts/AuthContext';


const DashboardPage = () => {
  const {  userSession } = useContext(AuthenticationContext);

  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [userData, setUserData] = useState(null)

  
  

  useEffect(() => {
    const fetchUserData = async() => {
    try {
      const sendableObject = {
        userSession,
      }
      const response = await EmailingAPI.post("get-user-campaigns/", sendableObject)
      //console.log(sendableObject);
      
      if (response.status === 200) {
        //console.log(response.data);
        
        setUserData({
          senderEmailAddresses: response.data,
          draftCampaigns: campaignExtractor(response.data,'draft_campaigns'),
          sentCampaigns: campaignExtractor(response.data, 'sent_campaigns'),
          scheduledCampaigns: campaignExtractor(response.data,'scheduled_campaigns')
        })
        setLoading(false);
      }
      //console.log(userData);
    } catch (error) {
        console.error('Error fetching emails:');
        setFetchError("There was an error retrieving your account data.")
        setLoading(false);
    } 
    }

      fetchUserData();


  }, [userSession]); 

  return (
    <>
    <div className='container'>
      {userData && !loading ? (
        <>

      <div>
        <SenderEmailLists senderEmails={userData.senderEmailAddresses} setUserData={setUserData}/>
      </div>
      <div className="w-100">
        <TabBar userData={userData} setUserData={setUserData}/>
      </div>
        </>
      )
      
      : 
      null}
      
    </div>
    {loading ? <LoadingIcon/> : null}
    {fetchError && <ErrorModal errorMessage={fetchError} setErrorMessage={setFetchError}/>}
    </>
  )
}

export default DashboardPage
