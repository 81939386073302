import React from 'react'
import { useEffect, useState } from 'react';
import InlineError from '../errors/InlineError'
import "../styles/ModalStyles.css"


const InfoModal = ({ setModalConfig, modalConfig, parentSpecificFunction, errorMessage, setErrorMessage}) => {
  
  const [error, setError] = useState(null)

  useEffect(() => {
    if (errorMessage) {
        setError(errorMessage)
    }
    }, [errorMessage])

    if (modalConfig.modalVisible === false) {
        return null;
      }
    const closeModal = () => {
        setModalConfig(prevConfig => ({
            ...prevConfig,
            modalVisible: false
        }));
      }
  

  
  return (
    <div>
    {modalConfig ? 
     <>
     <div className="modal-backdrop-custom"></div> 
      <div className="modal" tabIndex="-1" role="dialog" style={{display: 'block'}}> 
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header" style={modalConfig.headerStyles}>
              <h5 className="modal-title">{modalConfig.modalHeader}</h5>
            </div>
            <div className="modal-body">

              {modalConfig.modalBody}

            </div>

            <div className="modal-footer custom-modal-footer">
              {modalConfig?.showActionButton === false 
              ? 
              null 
              : 
              <button type="button" className={`${modalConfig.buttonStyles}`} 
              onClick={parentSpecificFunction}>
                {modalConfig.buttonMessage}
                </button>}
              <button type="button" className="btn btn-secondary" onClick={() => closeModal()}>Close</button>
            </div>
            {errorMessage && <InlineError errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>}
          </div>
        </div>
      </div>
      
      </>
      :
      null}
    </div>
  )
}

export default InfoModal
